import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { APIRequest } from "../helpers";

const JwtToken = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      localStorage.setItem("token", token);

      APIRequest("user/me", "GET").then((response) => {
        localStorage.setItem("userData", JSON.stringify(response?.data));
        window.location.reload();
      });
    }
    navigate("/");
  }, [location, navigate]);

  return null;
};

export default JwtToken;
