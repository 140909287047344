// all routes
import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import { useRedux } from "./hooks";
import { loginUser } from "./redux/actions";
// helpers
import { configureFakeBackend } from "./helpers";

// For Default import Theme.scss
import "./assets/scss/Theme.scss";

const App = () => {
  configureFakeBackend();
  return <Routes />;
};

export default App;
